<spb-breadcrumbs [links]="[KUNDSKYDD_URL,KUNDSKYDD_PRIVAT_URL]"
                 path="Kundskydd | Kundkännedom - Minderårig | Formulär"></spb-breadcrumbs>

<div class="spb-plain-content mat-typography">
  <ng-template #intro>
    <h1 class="mat-display-1 header">Kundkännedom</h1>
    <h3>Hej!</h3>
    <h4>Vi behöver ställa några snabba frågor till alla våra kunder</h4>
    <p>Enligt lag måste vi som bank ha en dokumenterad kännedom om samtliga våra
      kunder, även den som är minderårig.
      Omyndiga kan inte själv lämna in sina uppgifter och därför behöver du som
      förmyndare hjälpa din minderåriga att
      skicka in sin kundkännedom.
      Formuläret nedan syftar till att ge oss svar angående den minderårigas
      engagemang som privatkund hos oss.</p>
    <p>
      När formuläret är ifyllt behöver samtliga förmyndare signera med BankID.
      Finns det två förmyndare
      behövs två signeringar men de behöver inte ske samtidigt, utan det går bra
      att logga in senare och
      komplettera med ytterligare en signering.
    </p>
    <h4>Varför frågar vi våra kunder om detta?</h4>
    <p>Lag (2017:630) om åtgärder mot penningtvätt och finansiering av terrorism
      innebär i korthet att alla
      banker måste ha en god kunskap om sina kunder och deras affärer så att
      varken kunder eller bank
      utnyttjas i penningtvättssyfte. Vi ställer därför ett antal frågor om våra
      kunder så att vi kan tillvarata
      kundens ekonomiska intressen, samtidigt som vi tar vårt samhällsansvar.
      Mer information om varför
      vi ställer dessa frågor kan du hitta <a
        href="https://www.swedishbankers.se/foer-bankkunder/penningtvaett/daerfoer-maaste-banken-staella-fraagor/">här</a>.
      När du svarar på dessa frågor om din minderåriga vill vi att du tar
      utgångspunkt i hur affärerna med banken ser
      ut idag. Den information vi får ta del av behandlas
      självfallet konfidentiellt och omfattas av banksekretess samt <a
        href="https://www.sparbankensyd.se/sv/om-oss/sakerhet-kundskydd/integritet-och-sekretess-gdpr">dataskyddsförordningen
        (GDPR)</a>.</p>
  </ng-template>

  @if (adminService.adminData$ | async) {
    <div class="supervisor-margin">
      <spb-supervisor></spb-supervisor>
    </div>
  } @else {
    <ng-container *ngTemplateOutlet="intro"></ng-container>
  }

  <mat-vertical-stepper [linear]="true" spbStepperScroll>
    <mat-step [stepControl]="aboutMeForm" label="Uppgifter om den minderåriga">
      <form [formGroup]="aboutMeForm">
        <div class="input-with-button">
          <mat-form-field class="standard-full-width margin-top-small"
                          hideRequiredMarker>
            <mat-label>För- och efternamn</mat-label>
            <label>
              <input formControlName="name"
                     matInput
                     type="text">
            </label>
          </mat-form-field>
          @if (mockEnabled) {
            <button (click)="setData()"
                    color="primary" mat-button mat-stroked-button>Mocka data
            </button>
          }
        </div>
        <div class="input-with-button">
          <mat-form-field class="standard-full-width" hideRequiredMarker>
            <mat-label>Personnummer</mat-label>
            <input formControlName="personNummer"
                   matInput
                   type="text">
          </mat-form-field>
          @if (mockEnabled) {
            <button (click)="getRandomPersonnummer()"
                    color="primary" mat-button mat-stroked-button>Slumpa
            </button>
          }
        </div>
        <mat-form-field class="standard-full-width" hideRequiredMarker>
          <mat-label>Utdelningsadress</mat-label>
          <label>
            <input formControlName="address"
                   matInput
                   type="text">
          </label>
        </mat-form-field>
        <div formGroupName="co">
          <p>Har den minderåriga c/o adress?</p>
          <div class="standard-full-width radios">
            <mat-radio-group
              aria-label="Har du c/o adress?"
              color="primary"
              formControlName="careOf"
              ngDefaultControl
              required>
              <mat-radio-button [checked]="careOfSelect === '1'" value="1">Nej
              </mat-radio-button>
              <mat-radio-button [checked]="careOfSelect === '2'" value="2">Ja
              </mat-radio-button>
            </mat-radio-group>
          </div>
          @if (careOfSelect === '2') {
            <div>
              <mat-form-field class="standard-full-width" hideRequiredMarker>
                <mat-label>c/o</mat-label>
                <input formControlName="coAddress"
                       matInput
                       name="careOf"
                       type="text">
              </mat-form-field>
            </div>
          }
        </div>
        <mat-form-field class="standard-full-width" hideRequiredMarker>
          <mat-label>Postnummer</mat-label>
          <label>
            <input formControlName="postalCode"
                   matInput
                   type="text">
          </label>
        </mat-form-field>
        <mat-form-field class="standard-full-width" hideRequiredMarker>
          <mat-label>Postort</mat-label>
          <label>
            <input formControlName="postalArea"
                   matInput
                   type="text">
          </label>
        </mat-form-field>
        <mat-form-field class="standard-full-width" hideRequiredMarker>
          <mat-label>Land</mat-label>
          <label>
            <input formControlName="country"
                   matInput
                   type="text">
          </label>
        </mat-form-field>
        <!-- country of citizen !-->
        <p class="lead-text">Vilket land eller länder är den minderåriga
          medborgare i?</p>
        <mat-form-field class="standard-full-width margin-top-small">
          <label>
            <input
              #citizenInput
              (ngModelChange)="filterCountries($event)"
              [formControl]="citizenCtrl"
              [matAutocomplete]="auto"
              [matChipInputFor]="chipList"
              [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
              matInput
              placeholder="Välj länder">
          </label>
          <mat-autocomplete #auto="matAutocomplete"
                            (optionSelected)="addCitizenship($event)">
            @for (country of filteredCountries | async; track country.name) {
              <mat-option [value]="country.name" class="auto-list">
                {{ country.name }}
              </mat-option>
            }
          </mat-autocomplete>
        </mat-form-field>
        <div class="chips-och-dip">
          <mat-chip-grid #chipList aria-label="Role selection"
                         formArrayName="citizenOf">
            @for (country of citizenOf.controls; track $index) {
              <mat-chip-row
                (removed)="removeCitizenship($index)"
                [removable]="true"
              >
                {{ country.value }}
                <mat-icon matChipRemove>cancel</mat-icon>
              </mat-chip-row>
            }
          </mat-chip-grid>
        </div>
        <div class="radio-text">Är den minderåriga skatteskyldig i USA med
          anledning av medborgarskap, hemvist eller
          annat skäl?
        </div>
        <div class="standard-full-width radios">
          <mat-radio-group
            aria-label=""
            color="primary"
            formControlName="taxUSA"
            name="taxUSA"
            ngDefaultControl
            required>
            <mat-radio-button
              [checked]="aboutMeForm.controls.taxUSA.value === 'Ja'" value="Ja">
              Ja
            </mat-radio-button>
            <mat-radio-button
              [checked]="aboutMeForm.controls.taxUSA.value === 'Nej'"
              value="Nej">Nej
            </mat-radio-button>
          </mat-radio-group>
        </div>
      </form>
      @if (errorMessage) {
        <div class="spb-error">
          {{ errorMessage }}
        </div>
      }

      <div class="buttons">
        <button
          [disabled]="aboutMeForm.invalid"
          color="primary" mat-raised-button matStepperNext type="button">Nästa
        </button>
      </div>

    </mat-step>
    <mat-step label="Den minderårigas banktjänster">
      <form [formGroup]="myEconomyForm">
        <p>Vilka av följande banktjänster använder den minderåriga?</p>
        <div class="check-list">
          <mat-checkbox color="primary" formControlName="privateAccount"
                        ngDefaultControl>Privatkonto
          </mat-checkbox>
          <mat-checkbox color="primary" formControlName="bankCard"
                        ngDefaultControl>Bankkort Visa Online
          </mat-checkbox>
          <mat-checkbox color="primary" formControlName="savingsAccount"
                        ngDefaultControl>Sparkonto
          </mat-checkbox>
          <mat-checkbox color="primary" formControlName="fundAccount"
                        ngDefaultControl>Fondkonto
          </mat-checkbox>
          <mat-checkbox color="primary" formControlName="investmentAccount"
                        ngDefaultControl>Investeringssparkonto
          </mat-checkbox>
          <mat-checkbox color="primary" formControlName="internetBank"
                        ngDefaultControl>Internetbanken Ung (inkl BankID och
            Swish)
          </mat-checkbox>
        </div>
      </form>

      <div class="margin-top-button">
        <div class="buttons">
          <button
            [disabled]="myEconomyForm.invalid"
            color="primary" mat-raised-button matStepperNext type="button">
            Nästa
          </button>
        </div>
      </div>
    </mat-step>

    <mat-step label="Den minderårigas insättningar">
      <form [formGroup]="depositsForm">
        <p class="lead-text head-space">Hur mycket uppskattar du som förmyndare
          att det kommer att sättas in på den minderårigas konton per år?</p>
        <mat-form-field class="standard-full-width margin-top-small">
          <mat-label>Välj i listan</mat-label>
          <mat-select [compareWith]="compareDeposits"
                      formControlName="deposits"
                      ngDefaultControl>
            @for (item of cashDepositCountOptions; track $index) {
              <mat-option [value]="item">{{ item.name }}</mat-option>
            }
          </mat-select>
        </mat-form-field>

        <div class="radio-text">Planerar den minderåriga att göra insättningar i
          kassan på ett av Sparbanken Syds
          kontor?
        </div>
        <div class="standard-full-width radios">
          <mat-radio-group
            aria-label=""
            color="primary"
            formControlName="cashDeposits"
            name="cashDeposit"
            ngDefaultControl
            required>
            <mat-radio-button
              [checked]="depositsForm.controls.cashDeposits.value === 'Ja'"
              value="Ja">Ja
            </mat-radio-button>
            <mat-radio-button
              [checked]="depositsForm.controls.cashDeposits.value === 'Nej'"
              value="Nej">Nej
            </mat-radio-button>
          </mat-radio-group>
        </div>
      </form>
      <div class="buttons head-space">
        <button
          [disabled]="depositsForm.invalid"
          color="primary" mat-raised-button matStepperNext type="button">Nästa
        </button>
      </div>
    </mat-step>

    <mat-step label="Förmyndare">
      <form [formGroup]="guardiansForm">
        <div
          class="guardian-margin-bottom"
          formArrayName="guardians"
        >
          @for (guardian of guardians.controls; track $index; ) {
            <div [formGroupName]="$index">
              <div class="remove-guardian">
                <div [style.margin-bottom.px]="6">Förmyndare {{ $index + 1 }}</div>
                @if ($index === 1) {
                  <div class="guardian-button">
                <span
                  (click)="removeGuardian($index)"
                  (keydown.enter)="removeGuardian($index)"
                  (keydown.space)="removeGuardian($index)"
                  tabindex="0"
                  role="button"
                  aria-label="Ta bort vårdnadshavare"
                  class="material-icons"
                >clear</span>
                  </div>
                }
              </div>
              @if (this.finalizer.admin) {
                <div>
                  <mat-checkbox color="primary" formControlName="signAtOffice"
                                ngDefaultControl>
                    Signerar nu
                  </mat-checkbox>
                </div>
              }

              <mat-form-field
                class="standard-full-width"
                hideRequiredMarker
              >
                <mat-label>Namn</mat-label>
                <input formControlName="name" matInput type="text"/>
              </mat-form-field>
              <mat-form-field
                class="standard-full-width"
                hideRequiredMarker
              >
                <mat-label>Personnummer</mat-label>
                <input
                  (blur)="clearSubmitError()"
                  formControlName="personNummer"
                  matInput
                  type="text"
                />
              </mat-form-field>
              <mat-form-field
                class="standard-full-width"
                hideRequiredMarker
              >
                <mat-label>E-post</mat-label>
                <input formControlName="email" matInput type="text"/>
              </mat-form-field>
              <br>
            </div>
          }
        </div>

        <div class="buttons">
          @if (guardians.length < 2) {
            <button
              (click)="addGuardian()"
              color="primary"
              mat-raised-button
              type="button"
            >
              Lägg till förmyndare
            </button>
          }
          <button
            (click)="submit()"
            [disabled]="masterForm.invalid"
            color="primary"
            mat-raised-button
            type="button"
          >
            Skicka in
          </button>

          @if (submitError) {
            <div class="spb-error">{{ submitError }}</div>
          }
        </div>
      </form>
    </mat-step>
  </mat-vertical-stepper>
</div>